var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c("fieldset", [
        _c("p", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user,
                expression: "user",
              },
            ],
            attrs: {
              id: "user",
              type: "text",
              name: "user",
              autocapitalize: "off",
              placeholder: _vm.t("core", "Username or email"),
              "aria-label": _vm.t("core", "Username or email"),
              required: "",
            },
            domProps: { value: _vm.user },
            on: {
              change: _vm.updateUsername,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.user = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("label", { staticClass: "infield", attrs: { for: "user" } }, [
            _vm._v(_vm._s(_vm.t("core", "Username or email"))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { id: "reset-password-wrapper" } }, [
          _c("input", {
            staticClass: "login primary",
            attrs: { id: "reset-password-submit", type: "submit", title: "" },
            domProps: { value: _vm.t("core", "Reset password") },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "submit-icon",
            class: {
              "icon-confirm-white": !_vm.loading,
              "icon-loading-small": _vm.loading && _vm.invertedColors,
              "icon-loading-small-dark": _vm.loading && !_vm.invertedColors,
            },
          }),
        ]),
        _vm._v(" "),
        _vm.message === "send-success"
          ? _c("p", { staticClass: "update" }, [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(
                    _vm.t(
                      "core",
                      "A password reset message has been sent to the email address of this account. If you do not receive it, check your spam/junk folders or ask your local administrator for help."
                    )
                  ) +
                  "\n\t\t\t"
              ),
              _c("br"),
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(
                    _vm.t(
                      "core",
                      "If it is not there ask your local administrator."
                    )
                  ) +
                  "\n\t\t"
              ),
            ])
          : _vm.message === "send-error"
          ? _c("p", { staticClass: "update warning" }, [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(
                    _vm.t(
                      "core",
                      "Couldn't send reset email. Please contact your administrator."
                    )
                  ) +
                  "\n\t\t"
              ),
            ])
          : _vm.message === "reset-error"
          ? _c("p", { staticClass: "update warning" }, [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(
                    _vm.t(
                      "core",
                      "Password cannot be changed. Please contact your administrator."
                    )
                  ) +
                  "\n\t\t"
              ),
            ])
          : _vm.message
          ? _c("p", { staticClass: "update", class: { warning: _vm.error } })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.$emit("abort")
              },
            },
          },
          [
            _vm._v(
              "\n\t\t\t" + _vm._s(_vm.t("core", "Back to login")) + "\n\t\t"
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }