var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return (_vm.isHttps || _vm.isLocalhost) && _vm.hasPublicKeyCredential
    ? _c(
        "form",
        {
          ref: "loginForm",
          attrs: { method: "post", name: "login" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "fieldset",
            [
              _c("p", { staticClass: "grouptop groupbottom" }, [
                _c(
                  "label",
                  { staticClass: "infield", attrs: { for: "user" } },
                  [_vm._v(_vm._s(_vm.t("core", "Username or	email")))]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user,
                      expression: "user",
                    },
                  ],
                  ref: "user",
                  attrs: {
                    id: "user",
                    type: "text",
                    name: "user",
                    autocomplete: _vm.autoCompleteAllowed ? "on" : "off",
                    placeholder: _vm.t("core", "Username or email"),
                    "aria-label": _vm.t("core", "Username or email"),
                    required: "",
                  },
                  domProps: { value: _vm.user },
                  on: {
                    change: function ($event) {
                      return _vm.$emit("update:username", _vm.user)
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.user = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              !_vm.validCredentials
                ? _c(
                    "div",
                    {
                      staticClass:
                        "body-login-container update form__message-box",
                    },
                    [
                      _vm._v(
                        "\n\t\t\t" +
                          _vm._s(
                            _vm.t(
                              "core",
                              "Your account is not setup for passwordless login."
                            )
                          ) +
                          "\n\t\t"
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.validCredentials
                ? _c("LoginButton", {
                    attrs: {
                      loading: _vm.loading,
                      "inverted-colors": _vm.invertedColors,
                    },
                    on: { click: _vm.authenticate },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      )
    : !_vm.hasPublicKeyCredential
    ? _c(
        "div",
        { staticClass: "body-login-container update" },
        [
          _c("InformationIcon", { attrs: { size: "70" } }),
          _vm._v(" "),
          _c("h2", [_vm._v(_vm._s(_vm.t("core", "Browser not supported")))]),
          _vm._v(" "),
          _c("p", { staticClass: "infogroup" }, [
            _vm._v(
              "\n\t\t" +
                _vm._s(
                  _vm.t(
                    "core",
                    "Passwordless authentication is not supported in your browser."
                  )
                ) +
                "\n\t"
            ),
          ]),
        ],
        1
      )
    : !_vm.isHttps && !_vm.isLocalhost
    ? _c(
        "div",
        { staticClass: "body-login-container update" },
        [
          _c("LockOpenIcon", { attrs: { size: "70" } }),
          _vm._v(" "),
          _c("h2", [
            _vm._v(_vm._s(_vm.t("core", "Your connection is not secure"))),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "infogroup" }, [
            _vm._v(
              "\n\t\t" +
                _vm._s(
                  _vm.t(
                    "core",
                    "Passwordless authentication is only available over a secure connection."
                  )
                ) +
                "\n\t"
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }