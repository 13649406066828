//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { loadState } from '@nextcloud/initial-state';
import queryString from 'query-string';
import LoginForm from '../components/login/LoginForm.vue';
import PasswordLessLoginForm from '../components/login/PasswordLessLoginForm.vue';
import ResetPassword from '../components/login/ResetPassword.vue';
import UpdatePassword from '../components/login/UpdatePassword.vue';
var query = queryString.parse(location.search);

if (query.clear === '1') {
  try {
    window.localStorage.clear();
    window.sessionStorage.clear();
    console.debug('Browser storage cleared');
  } catch (e) {
    console.error('Could not clear browser storage', e);
  }
}

export default {
  name: 'Login',
  components: {
    LoginForm: LoginForm,
    PasswordLessLoginForm: PasswordLessLoginForm,
    ResetPassword: ResetPassword,
    UpdatePassword: UpdatePassword
  },
  data: function data() {
    return {
      loading: false,
      user: loadState('core', 'loginUsername', ''),
      passwordlessLogin: false,
      resetPassword: false,
      // Initial data
      errors: loadState('core', 'loginErrors', []),
      messages: loadState('core', 'loginMessages', []),
      redirectUrl: loadState('core', 'loginRedirectUrl', false),
      throttleDelay: loadState('core', 'loginThrottleDelay', 0),
      invertedColors: OCA.Theming && OCA.Theming.inverted,
      canResetPassword: loadState('core', 'loginCanResetPassword', false),
      resetPasswordLink: loadState('core', 'loginResetPasswordLink', ''),
      autoCompleteAllowed: loadState('core', 'loginAutocomplete', true),
      resetPasswordTarget: loadState('core', 'resetPasswordTarget', ''),
      resetPasswordUser: loadState('core', 'resetPasswordUser', ''),
      directLogin: query.direct === '1',
      hasPasswordless: loadState('core', 'webauthn-available', false),
      countAlternativeLogins: loadState('core', 'countAlternativeLogins', false),
      isHttps: window.location.protocol === 'https:',
      isLocalhost: window.location.hostname === 'localhost',
      hasPublicKeyCredential: typeof window.PublicKeyCredential !== 'undefined',
      hideLoginForm: loadState('core', 'hideLoginForm', false)
    };
  },
  methods: {
    passwordResetFinished: function passwordResetFinished() {
      this.resetPasswordTarget = '';
      this.directLogin = true;
    }
  }
};