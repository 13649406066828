var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.hideLoginForm || _vm.directLogin
    ? _c(
        "div",
        [
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            !_vm.passwordlessLogin &&
            !_vm.resetPassword &&
            _vm.resetPasswordTarget === ""
              ? _c(
                  "div",
                  { key: "login" },
                  [
                    _c("LoginForm", {
                      attrs: {
                        username: _vm.user,
                        "redirect-url": _vm.redirectUrl,
                        "direct-login": _vm.directLogin,
                        messages: _vm.messages,
                        errors: _vm.errors,
                        "throttle-delay": _vm.throttleDelay,
                        "inverted-colors": _vm.invertedColors,
                        "auto-complete-allowed": _vm.autoCompleteAllowed,
                      },
                      on: {
                        "update:username": function ($event) {
                          _vm.user = $event
                        },
                        submit: function ($event) {
                          _vm.loading = true
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.canResetPassword && _vm.resetPasswordLink !== ""
                      ? _c(
                          "a",
                          {
                            attrs: {
                              id: "lost-password",
                              href: _vm.resetPasswordLink,
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(_vm.t("core", "Forgot password?")) +
                                "\n\t\t\t"
                            ),
                          ]
                        )
                      : _vm.canResetPassword && !_vm.resetPassword
                      ? _c(
                          "a",
                          {
                            attrs: {
                              id: "lost-password",
                              href: _vm.resetPasswordLink,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.resetPassword = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(_vm.t("core", "Forgot password?")) +
                                "\n\t\t\t"
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _vm.hasPasswordless
                      ? [
                          _vm.countAlternativeLogins
                            ? _c("div", { staticClass: "alternative-logins" }, [
                                _vm.hasPasswordless
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "button",
                                        class: {
                                          "single-alt-login-option":
                                            _vm.countAlternativeLogins,
                                        },
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            _vm.passwordlessLogin = true
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.t(
                                                "core",
                                                "Log in with a device"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.passwordlessLogin = true
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.t("core", "Log in with a device")
                                      ) +
                                      "\n\t\t\t\t"
                                  ),
                                ]
                              ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : !_vm.loading && _vm.passwordlessLogin
              ? _c(
                  "div",
                  { key: "reset", staticClass: "login-additional" },
                  [
                    _c("PasswordLessLoginForm", {
                      attrs: {
                        username: _vm.user,
                        "redirect-url": _vm.redirectUrl,
                        "inverted-colors": _vm.invertedColors,
                        "auto-complete-allowed": _vm.autoCompleteAllowed,
                        "is-https": _vm.isHttps,
                        "is-localhost": _vm.isLocalhost,
                        "has-public-key-credential": _vm.hasPublicKeyCredential,
                      },
                      on: {
                        "update:username": function ($event) {
                          _vm.user = $event
                        },
                        submit: function ($event) {
                          _vm.loading = true
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.passwordlessLogin = false
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t" +
                            _vm._s(_vm.t("core", "Back")) +
                            "\n\t\t\t"
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : !_vm.loading && _vm.canResetPassword
              ? _c("div", { key: "reset", staticClass: "login-additional" }, [
                  _c(
                    "div",
                    { staticClass: "lost-password-container" },
                    [
                      _vm.resetPassword
                        ? _c("ResetPassword", {
                            attrs: {
                              username: _vm.user,
                              "reset-password-link": _vm.resetPasswordLink,
                              "inverted-colors": _vm.invertedColors,
                            },
                            on: {
                              "update:username": function ($event) {
                                _vm.user = $event
                              },
                              abort: function ($event) {
                                _vm.resetPassword = false
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm.resetPasswordTarget !== ""
              ? _c(
                  "div",
                  [
                    _c("UpdatePassword", {
                      attrs: {
                        username: _vm.user,
                        "reset-password-target": _vm.resetPasswordTarget,
                        "inverted-colors": _vm.invertedColors,
                      },
                      on: {
                        "update:username": function ($event) {
                          _vm.user = $event
                        },
                        done: _vm.passwordResetFinished,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      )
    : _c(
        "div",
        [
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _c("div", { staticClass: "warning" }, [
              _vm._v(
                "\n\t\t\t" + _vm._s(_vm.t("core", "Login form is disabled."))
              ),
              _c("br"),
              _vm._v(" "),
              _c("small", [
                _vm._v(
                  _vm._s(_vm.t("core", "Please contact your administrator.")) +
                    "\n\t\t\t"
                ),
              ]),
            ]),
          ]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }