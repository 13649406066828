var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: { id: "submit-wrapper" },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("input", {
        staticClass: "login primary",
        attrs: { id: "submit-form", type: "submit", title: "" },
        domProps: {
          value: !_vm.loading
            ? _vm.t("core", "Log in")
            : _vm.t("core", "Logging in …"),
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "submit-icon",
        class: {
          "icon-confirm-white": !_vm.loading,
          "icon-loading-small": _vm.loading && _vm.invertedColors,
          "icon-loading-small-dark": _vm.loading && !_vm.invertedColors,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }