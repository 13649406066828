var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c("fieldset", [
        _c("p", [
          _c("label", { staticClass: "infield", attrs: { for: "password" } }, [
            _vm._v(_vm._s(_vm.t("core", "New password"))),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password",
              },
            ],
            attrs: {
              id: "password",
              type: "password",
              name: "password",
              autocomplete: "new-password",
              autocapitalize: "none",
              autocorrect: "off",
              required: "",
              placeholder: _vm.t("core", "New password"),
            },
            domProps: { value: _vm.password },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.password = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _vm.encrypted
          ? _c("div", { staticClass: "update" }, [
              _c("p", [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(
                      _vm.t(
                        "core",
                        "Your files are encrypted. There will be no way to get your data back after your password is reset. If you are not sure what to do, please contact your administrator before you continue. Do you really want to continue?"
                      )
                    ) +
                    "\n\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.proceed,
                    expression: "proceed",
                  },
                ],
                staticClass: "checkbox",
                attrs: { id: "encrypted-continue", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.proceed)
                    ? _vm._i(_vm.proceed, null) > -1
                    : _vm.proceed,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.proceed,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.proceed = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.proceed = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.proceed = $$c
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "encrypted-continue" } }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.t("core", "I know what I'm doing")) +
                    "\n\t\t\t"
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { attrs: { id: "submit-wrapper" } }, [
          _c("input", {
            staticClass: "login primary",
            attrs: { id: "submit", type: "submit", title: "" },
            domProps: {
              value: !_vm.loading
                ? _vm.t("core", "Reset password")
                : _vm.t("core", "Resetting password"),
            },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "submit-icon",
            class: {
              "icon-loading-small": _vm.loading && _vm.invertedColors,
              "icon-loading-small-dark": _vm.loading && !_vm.invertedColors,
            },
          }),
        ]),
        _vm._v(" "),
        _vm.error && _vm.message
          ? _c("p", { class: { warning: _vm.error } }, [
              _vm._v("\n\t\t\t" + _vm._s(_vm.message) + "\n\t\t"),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }